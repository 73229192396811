import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import ada from '../assets/images/ada.svg'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Staking your ADA with StakeNode.One" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <h2><img className="tokenicon" src={ada} alt="" width="50"/> Cardano</h2>
            <p>
              Annual Reward Rate: 3% - 12%<br />
              Comission: 0.8% until 2021. 1.6% after<br />
              Payout Frequency: Approximately every 5 days<br />
            </p>
            <p>
              <h3>What is Cardano?</h3>
              Cardano (ADA) is a decentralised public blockchain and cryptocurrency project and is fully open source. Cardano is built in the secure Haskell programming language. Cardano is developing a smart contract platform which seeks to deliver lots of advanced features. It claims to be the first blockchain platform to evolve out of a scientific philosophy and a research-first driven approach.
              <br />
              <br />
              <h3>The goal of Cardano</h3>
              In 2015, the Cardano project began as an effort to change the way cryptocurrencies are designed and developed. The overall focus beyond a particular set of innovations is to provide a more balanced and sustainable ecosystem that better accounts for the needs of its users as well as other systems seeking integration. Cardano did not begin with a comprehensive roadmap or even an authoritative white paper. It started with a collection of design principles, engineering best practices and avenues for exploration. The Cardano platform now features decentralized applications and contracts that operate at low cost in a secure and scalable way.
              <br />
              <br />
              <h3>Who started Cardano</h3>
              Cardano was launched in 2017, and founded by Charles Hoskinson, co-founder of Ethereum. Charles said he wanted to provide a more balanced and sustainable ecosystem for cryptocurrencies. The development team consists of a large global collective of expert engineers and researchers.
            </p>
            <h2>How to Stake with Stakenode.one</h2>
            <p>
              Our pool id is: 29b4f77575cf0f0bd5507d1773fea1d64a3dee78d215dd57f2acc3d4 <br />
              Our ticker id is: SNODE <br />
            </p>  
            <p>
              Stake your ADA with us to earn passive rewards using your Daedalus, Adalite or Yoroi wallets. <br />
              You can search by our pool id or ticker. <br />
              You can see our pool performance at <a href="https://pooltool.io/pool/29b4f77575cf0f0bd5507d1773fea1d64a3dee78d215dd57f2acc3d4/blocks">pooltool.io</a>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
